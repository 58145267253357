import { Injectable } from '@angular/core';
import { CanLoad, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { FirebaseService } from '../_services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private authService: FirebaseService, private router: Router){}

  canLoad(): Observable<boolean>{
    return this.authService.getAuthentication().pipe(
      filter(val => val !== null),
      take(1),
      map(isAuth => {
        if(isAuth){
          return true;
        } else {
          this.router.navigateByUrl('/login');
          return false;
        }
      })
    );
  }
}
