import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,  } from '@angular/router';
import { filter, take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FirebaseService } from '../_services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanActivate {
  constructor(private firebaseService: FirebaseService, private router: Router){

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.firebaseService.getAuthentication().pipe(
      filter(val => val !== null),
      take(1),
      map(isAuth => {
        if(isAuth){
          this.router.navigateByUrl('/tab-manager', {replaceUrl: true});
        }else{
          return true;
        }
      })
    );
  }
}
