import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, map,} from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '../_interfaces/user';
import { Absentstamp, TimestampList } from '../_interfaces/time-stamp-object';
import { AlertMessageService } from './alert-message.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  //-------------------- Variable - Constructor --------------------//

  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private timestampObservable: BehaviorSubject<TimestampList> = new BehaviorSubject<TimestampList>(null);
  private appUserObservable: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private appUserSubscriper: Subscription = null;

  private currentTimestampList: TimestampList;
  private appUser: User;


  constructor(
    private authFirebaseModul: AngularFireAuth,
    private firestoreModul: AngularFirestore,
    private alertMessageSevice: AlertMessageService,
  ) {
    this.authStatusListener(true);
  }

  //-------------------- Public Function --------------------//
  //-------------------- Get Function --------------------//

  getAuthentication(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  observeTimestamp(): Observable<TimestampList> {
    return this.timestampObservable.asObservable();
  }

  getAppUser(): Observable<User> {
    return this.appUserObservable.asObservable();
  }


  subscribeUserWithUid(_uid: string): Observable<User> {
    return this.firestoreModul.collection<User>('User').doc(_uid).valueChanges();
  }

  subscribeTimestampFromUser(_timestampString: string): Observable<TimestampList>{
    return this.firestoreModul.collection<TimestampList>('Timestamp').doc(_timestampString).valueChanges();
  }




  subscribeToAllUser(){
    return this.firestoreModul.collection<User>('User').snapshotChanges()
    .pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as User;
          const uid = a.payload.doc.id;
          return { uid, ...data };
        })
      )
    );
  }




  async getSpecificTimestampData(_specifigTimestampDoc: string): Promise<TimestampList> {
    let t_timestampList: TimestampList = this.setEmptyTimestampList(new Date(), 0);
    const t_doc = await this.firestoreModul.collection<TimestampList>('Timestamp').doc(_specifigTimestampDoc).ref.get();

    if (!t_doc.exists) {
      return t_timestampList;
    }

    return (t_timestampList = t_doc.data());
  }




  async setOvertime(_overtime: number, _docTimestampString: string) {
    try {
      await this.firestoreModul.collection('Timestamp').doc(_docTimestampString).update({ lastMonthOvertime: _overtime });
    } catch {
      this.alertMessageSevice.alertMessage('Überstunden','Konnte nicht angelegt werden.');
    }
  }




  async setAbsent(_stringForDataBase: string, _$absent: Absentstamp[], _userPersonalNumber: number) {
    const t_timestamp = await this.firestoreModul.collection('Timestamp').doc(_stringForDataBase).ref.get();

    if (!t_timestamp.exists) {
      const t_newTimestamp = {
        loginTime: [],
        logoutTime: [],
        month: _$absent[0].month,
        personalNumber: _userPersonalNumber,
        sickAndHoliday: _$absent,
        year: _$absent[0].year,
      }
      await this.firestoreModul.collection('Timestamp').doc(_stringForDataBase).set(t_newTimestamp);
      return;
    }

    if (t_timestamp.get('sickAndHoliday').empty) {
      await this.firestoreModul.collection('Timestamp').doc(_stringForDataBase).update({sickAndHoliday: _$absent});
    }

    const t_absentstampList: Absentstamp[] = t_timestamp.get('sickAndHoliday');
    _$absent.forEach((firstElement) => {
      let isDontHave = true;
      for (let i = 0; t_absentstampList.length > i; ++i) {
        if (
          firstElement.day === t_absentstampList[i].day &&
          firstElement.month === t_absentstampList[i].month &&
          firstElement.year === t_absentstampList[i].year)
        {
          isDontHave = false;
          break;
        }
      }
      if (isDontHave) {
        t_absentstampList.push(firstElement);
      }
    });
    t_absentstampList.sort((a, b) => a.day - b.day);
    await this.firestoreModul.collection('Timestamp').doc(_stringForDataBase).update({sickAndHoliday: t_absentstampList,});
  }




  async deleteAbsent(_stringForDataBase: string, _$absent: Absentstamp[], _userPersonalNumber: number) {
    const t_timestamp = await this.firestoreModul.collection('Timestamp').doc(_stringForDataBase).ref.get();
    if(!t_timestamp.exists){return;}

    const t_absentstampList: Absentstamp[] = await t_timestamp.get('sickAndHoliday');
    for (const t_currentDay of _$absent) {
      for (const t_absentDay of t_absentstampList) {
        if (
          t_currentDay.day === t_absentDay.day &&
          t_currentDay.month === t_absentDay.month &&
          t_currentDay.year === t_absentDay.year
        ) {
          t_absentstampList.splice(t_absentstampList.indexOf(t_absentDay), 1);
        }
      }
    }
    await this.firestoreModul.collection('Timestamp').doc(_stringForDataBase).update({ sickAndHoliday: t_absentstampList });
  }




  async addLoginTime() {
    if (this.appUser.isOnline) {
      return;
    }

    const t_currentTime = new Date();
    const t_timestampString = this.createTimestampString(t_currentTime);
    const t_timestamp: TimestampList = (await this.firestoreModul.collection<TimestampList>('Timestamp').doc(t_timestampString).ref.get()).data();

    if (!t_timestamp) {
      this.alertMessageSevice.alertMessage('Zeitstempel', 'Konnte nicht gefunden werden');
      return;
    }

  

    if (!this.appUser.hasAccessForTime) {
      if (t_currentTime.getHours() < 7) {
        t_currentTime.setHours(7);
        t_currentTime.setMinutes(0);
        this.alertMessageSevice.alertMessage('Einstempeln', 'Die Zeit wurde auf 7 Uhr gestellt.');
      }
    }

    const T_CURRENTTIMESTAMP = this.setCurrentTime(`${t_currentTime.getHours()}:${(t_currentTime.getMinutes()<10?`0${t_currentTime.getMinutes()}`:t_currentTime.getMinutes())}`,t_currentTime);
    t_timestamp.loginTime.push(T_CURRENTTIMESTAMP);

    await this.firestoreModul.collection('User').doc((await this.authFirebaseModul.currentUser).uid).update({
        isOnline: true,
        lastCheckIn: `${T_CURRENTTIMESTAMP.roundTime} (${t_currentTime.getDate()}.${t_currentTime.getMonth() + 1}.${t_currentTime.getFullYear()})`,
      });

    await this.firestoreModul.collection('Timestamp').doc(t_timestampString).update({ loginTime: t_timestamp.loginTime });
  }




  async addLogoutTime() {
    if (!this.appUser.isOnline) {
      return;
    }

    const t_currentTime = new Date();
    const t_timestampString = this.createTimestampString(t_currentTime);
    const t_Timestamp: TimestampList = (await this.firestoreModul.collection<TimestampList>('Timestamp').doc(t_timestampString).ref.get()).data();

    if (!t_Timestamp) {
      this.alertMessageSevice.alertMessage('Zeitstempel','Konnte nicht gefunden werden');
      return;
    }

    if (
      (t_currentTime.getHours() > 18 ||
        (t_currentTime.getHours() === 18 && t_currentTime.getMinutes() >= 1)) &&
      !this.appUser.hasAccessForTime
    ) {
      t_currentTime.setHours(18);
      t_currentTime.setMinutes(0);
      this.alertMessageSevice.alertMessage('Zu spät ausgeloggt','Ihre Zeit wurde auf 18:00 Uhr gestellt..');
    }

    const T_CURRENTTIMESTAMP = this.setCurrentTime(`${t_currentTime.getHours()}:${(t_currentTime.getMinutes()<10?`0${t_currentTime.getMinutes()}`:t_currentTime.getMinutes())}`, t_currentTime);
    T_CURRENTTIMESTAMP.time = t_currentTime.toString();
    t_Timestamp.logoutTime.push(T_CURRENTTIMESTAMP);

    await this.firestoreModul.collection('User').doc((await this.authFirebaseModul.currentUser).uid).update({
        isOnline: false,
        lastCheckOut: `${T_CURRENTTIMESTAMP.roundTime} (${t_currentTime.getDate()}.${t_currentTime.getMonth() + 1}.${t_currentTime.getFullYear()})`,
      });

    await this.firestoreModul.collection('Timestamp').doc(t_timestampString).update({ logoutTime: t_Timestamp.logoutTime });
  }



  addEmail(emailMap: Map<string, string[]>, t_month: number, t_year: number){
    for (let [supervisor, employes] of emailMap) {
      let supervisorEmail: string = `${supervisor.split(' ')[0]}.${supervisor.split(' ')[1]}@wieprecht.services`;
      let employesString: string = '';
      

      employes.forEach(worker => {
        employesString = `${employesString}${worker}`
      })

      let emailDoc = {
        to: [supervisorEmail],
        message: {
          subject: 'Deine Mitarbeiterzeiten',
          text: '',
          html: `<h1>Deine Mitarbeiterzeiten:</h1><h2>${t_month}.${t_year}</h2><p>${employesString}</p>`
        }
      }
      this.firestoreModul.collection('mail').add(emailDoc);
    }
  }



  //-------------------- Normal Function --------------------//
  async logout(): Promise<void> {
    await this.authFirebaseModul.signOut();
  }




  async resetPassword(email: string): Promise<void> {
    await this.authFirebaseModul.sendPasswordResetEmail(email);
  }




  async loginWithEmail(_data): Promise<boolean> {
    const T_USER = await this.authFirebaseModul.signInWithEmailAndPassword(_data.email, _data.password);
    if (T_USER.user.uid) {
      return true;
    } else {
      return false;
    }
  }




  async createCredentielForUser(_email: string, _passowrd: string){
    let currentUser: any;

    const newUserCredential = await this.authFirebaseModul.createUserWithEmailAndPassword(_email, _passowrd);
    this.authFirebaseModul.signInWithCredential(currentUser);

    return newUserCredential;
  }




  async createUserDoc(_user: User, _uid: string){
    this.firestoreModul.collection('User').doc(_uid).set(_user);
  }




  async updateUserDoc(_user){
    this.firestoreModul.collection('User').doc(_user.uid).update(_user);
  }




  async updateTimestampList(_docString: string, _timestampList: TimestampList){
    this.firestoreModul.collection('Timestamp').doc(_docString).update(_timestampList);
  }





  async checkPersonalNumber(_personalNumber): Promise<User | false> {
    const t_FoundPerson = await this.firestoreModul.collection<User>('User').ref.where('personalNumber', '==', _personalNumber).get();
    if (t_FoundPerson.empty) {
      return false;
    }
    let t_searchUser: User = null;
    t_FoundPerson.forEach((user) => {
      if (t_searchUser !== null) {
        this.alertMessageSevice.alertMessage('Personnalnummer', 'Was? Es exisitieren 2 Personen mit dieser Personalnummer?');
        return false;
      }
      t_searchUser = user.data();
    });
    return t_searchUser;
  }




  async downloadAllTimestampThisMonth(specificMonth: number,specificYear: number
  ) {
    const allTimestampQuery = await this.firestoreModul.collection<TimestampList>('Timestamp').ref.where('month', '==', specificMonth).where('year', '==', specificYear).get();
    if (allTimestampQuery.empty) {
      this.alertMessageSevice.alertMessage('Timestamps', 'Konnte nicht gefunden werden!');
      return null;
    }
    const allTimestamps: TimestampList[] = [];
    allTimestampQuery.forEach(timestampList => {
      allTimestamps.push(timestampList.data());
    });
    return allTimestamps;
  }




  private async checkTimestampsFromDataBase(_user: User) {
    const t_currentTime = new Date();
    const t_currentTimestampString = this.createTimestampString(t_currentTime);

    const checkedTimestamp = await this.firestoreModul.collection<TimestampList>('Timestamp').doc(t_currentTimestampString).ref.get()
    if(checkedTimestamp.exists){
      this.currentTimestampList = checkedTimestamp.data();
    }else{
      this.currentTimestampList = this.setEmptyTimestampList(t_currentTime, this.appUser.personalNumber);
      this.firestoreModul.collection('Timestamp').doc(t_currentTimestampString).set(this.currentTimestampList);
    }
  }




  private setCurrentTime(_calculatedRoundTime: string, _currentTime: Date) {
    return {
      roundTime: _calculatedRoundTime,
      time: _currentTime.toString(),
      day: _currentTime.getDate(),
      mark: 'S',
      month: _currentTime.getMonth() + 1,
      year: _currentTime.getFullYear(),
    };
  }




  private setEmptyTimestampList(_currentTime: Date, _pNumber: number): TimestampList {
    return {
      personalNumber: _pNumber,
      month: _currentTime.getMonth() + 1,
      year: _currentTime.getFullYear(),
      loginTime: [],
      logoutTime: [],
      sickAndHoliday: [],
      lastMonthOvertime: null,
    };
  }




  private createTimestampString(_currentTime: Date): string {
    return `${_currentTime.getMonth() + 1}-${_currentTime.getFullYear()}_${
      this.appUser.personalNumber
    }`;
  }




  private authStatusListener(_oneTimeSwitch: boolean) {
    this.authFirebaseModul.onAuthStateChanged(async (credential) => {
      if (credential) {
        this.isAuthenticated.next(true);
        this.appUserSubscriper = this.subscribeUserWithUid(credential.uid).subscribe( async (user) => {
            if (!user) {return;}
            if(user !== this.appUser){

            }
            this.appUser = user;
            this.appUserObservable.next(user);
            if (_oneTimeSwitch) {
              _oneTimeSwitch = false;
              await this.checkTimestampsFromDataBase(user);
              if (this.currentTimestampList) {
                this.timestampObservable.next(this.currentTimestampList);
              }
            }
          }
        );
      } else {
        if(this.appUserSubscriper !== null){
          this.appUserSubscriper.unsubscribe();
        }
        this.isAuthenticated.next(false);
        this.appUser = null;
        this.currentTimestampList = null;
        this.timestampObservable.next(this.currentTimestampList);
        this.appUserObservable.next(this.appUser);
      }
    });
  }
}
