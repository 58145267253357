import { NgModule,  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { LoginPage } from './_pages/login/login.page';


@NgModule({
  declarations: [AppComponent, LoginPage],
  imports: [AngularFireModule.initializeApp(environment.firebase), BrowserModule, IonicModule.forRoot(), AppRoutingModule, ReactiveFormsModule, FormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, NetworkInterface],
  bootstrap: [AppComponent],
})
export class AppModule {}
