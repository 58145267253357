import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {

  constructor(private alertControllerModul: AlertController){
  }

  public async alertMessage(_headerText: string, _messageText: string){
    const alert = await this.alertControllerModul.create({
      header: _headerText,
      message: _messageText,
      buttons: ['OK'],
    });
    await alert.present();
  }
}
