// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-image {
  height: 120px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.background-image #wieprecht-service {
  font-size: 20px;
}
.background-image img {
  opacity: 50%;
  position: absolute;
  width: 120px;
  z-index: -1;
}

.inputStyle {
  border-radius: 5px;
  overflow: hidden;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.errors {
  font-size: small;
  color: white;
  background: var(--ion-color-danger);
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

form, visibleContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

ion-input {
  --background: #167ad1;
  --padding-start: 10px;
}

ion-button {
  --background: #005baa;
}

@media only screen and (min-width: 600px) {
  .inputStyle {
    width: 500px;
  }
  .background-image {
    height: 300px;
  }
  .background-image #wieprecht-service {
    font-size: 45px;
  }
  .background-image ion-label {
    font-size: 35px;
  }
  .background-image img {
    width: 300px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/_pages/login/login.page.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,YAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AAEJ;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,mCAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,qBAAA;EACA,qBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE;IACE,YAAA;EACF;EACA;IACE,aAAA;EACF;EAAE;IACE,eAAA;EAEJ;EAAE;IACE,eAAA;EAEJ;EAAE;IACE,YAAA;EAEJ;AACF","sourcesContent":[".background-image {\r\n  height: 120px;\r\n  margin-bottom: 10px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  #wieprecht-service{\r\n    font-size: 20px;\r\n  }\r\n  img{\r\n    opacity: 50%;\r\n    position: absolute;\r\n    width: 120px;\r\n    z-index: -1;\r\n  }\r\n}\r\n\r\n.inputStyle{\r\n  border-radius: 5px;\r\n  overflow: hidden;\r\n  width: 80%;\r\n  margin-left: 10%;\r\n  margin-right: 10%;\r\n}\r\n\r\n.errors{\r\n  font-size: small;\r\n  color: white;\r\n  background: var(--ion-color-danger);\r\n  padding-left: 15px;\r\n  padding-top: 5px;\r\n  padding-bottom: 5px;\r\n}\r\n\r\nform, visibleContainer{\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\nion-input{\r\n  --background: #167ad1;\r\n  --padding-start: 10px;\r\n}\r\n\r\nion-button{\r\n  --background: #005baa;\r\n}\r\n\r\n@media only screen and (min-width: 600px) {\r\n  .inputStyle{\r\n    width: 500px;\r\n  }\r\n  .background-image {\r\n    height: 300px;\r\n    #wieprecht-service{\r\n      font-size: 45px;\r\n    }\r\n    ion-label{\r\n      font-size: 35px;\r\n    }\r\n    img{\r\n      width: 300px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
